// import jwt from 'jsonwebtoken'
// import { v4 } from 'uuid'
import { infoStorage, setStorage } from '../general'

const parseJson = async response => {
  const text = await response.text();
  try {
    const json = JSON.parse(text);
    return json;
  } catch (err) {
    return text;
  }
};

/**
 * Shopify API Helper
 *
 * Handles the formatting of the URL and actioning the fetch function
 *
 * @param   {String} endpoint       The Shopify API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * @param   {Number} version        (Optional) Version of the API to hit. 3 is assumed
 * @param   {String} overrideCartId (Optional) Provide an existing cartId to set the session to
 *
 * @return  {Object}                {response, status}
 *
    import { shopifyApi } from '../helpers/shopify'

    shopifyApi('endpoint', 'POST', bodyObject, version, cartId).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function shopifyApi(endpoint, method, body, version, overrideCartId) {
  const options = {
    method: method ? method : 'GET',
    credentials: 'same-origin',
    mode: 'same-origin'
  };

  if (body) {
    let bodyString = body;
    if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
    }

    options.body = bodyString;
  }

  const encodedEndpoint = Buffer.from(endpoint).toString('base64');
//   const userToken = getStorage('_loggedIn');

  if (!(typeof fetch === 'undefined')) {
    return await fetch(
      `${process.env.LAMBDA_PATH}shopify?action=${encodedEndpoint}`, // &ut=${userToken}
      options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
  } else {
    return { response: null, status: 404 }
  }
}

async function stageUpload(image) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('stageUpload', 'POST', {image, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function fileUpload(resourceUrl, type) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('fileUpload', 'POST', {resourceUrl, type, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function fileDelete(ids) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('fileDelete', 'POST', {ids, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function getMedia(mediaId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('getMedia', 'POST', {mediaId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function setMetafield(metafield) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('setMetafield', 'POST', {metafield, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function getCompanyMembers(companyId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('getCompanyMembers', 'POST', {companyId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function searchProducts(keyword) {
    return shopifyApi('searchProducts', 'POST', {keyword});
}

async function updateAddress(address) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('updateAddress', 'POST', {address, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function createAddress(address) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('createAddress', 'POST', {address, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function deleteAddress(addressId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('deleteAddress', 'POST', {addressId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}



async function setDefaultAddress(addressId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('setDefaultAddress', 'POST', {addressId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function updateUser(object) {
  const token = infoStorage('_loggedIn');
  if (token) {
    const response = await shopifyApi('updateUser', 'POST', {object, token: token.value});
    if (String(response.status).startsWith('2') && 'customerUpdate' in response.response.data && 'signedToken' in response.response.data.customerUpdate) {
      setStorage('_loggedIn', response.response.data.customerUpdate.signedToken, token.storage === 'sessionStorage' ? true : false);
    }
    return response;
  } else {
    return { response: null, status: 404 }
  }
}

async function getUserWishlists() {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('getUserWishlists', 'POST', {token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function associateCustomerToOrder(checkoutId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('associateCustomerToOrder', 'POST', {checkoutId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function disAssociateCustomerToOrder(checkoutId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('disAssociateCustomerToOrder', 'POST', {checkoutId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function updateCompanyMember(companyContactId, companyContactObject) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('updateCompanyMember', 'POST', {companyContactId, companyContactObject, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function addCompanyMember(companyId, companyContactObject) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('addCompanyMember', 'POST', {companyId, companyContactObject, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function assignMemberRole(memberRoleObj) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('assignMemberRole', 'POST', {memberRoleObj, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function deleteCompanyMember(companyContactId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('deleteCompanyMember', 'POST', {companyContactId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function deleteCustomer(customerId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('deleteCustomer', 'POST', {customerId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function getCompanyLocations(companyId) {
  const token = infoStorage('_loggedIn');
  if (token) {
    return shopifyApi('getCompanyLocations', 'POST', {companyId, token: token.value});
  } else {
    return { response: null, status: 404 }
  }
}

async function forgotPassword(email) {
  return shopifyApi('forgotPassword', 'POST', {email});
}

async function cartCreate(input) {
  return shopifyApi('cartCreate', 'POST', {input});
}

async function cartAddItems(cartId, lines) {
  return shopifyApi('cartAddItems', 'POST', {cartId, lines});
}

async function cartUpdateItem(cartId, lines) {
  return shopifyApi('cartUpdateItem', 'POST', {cartId, lines});
}

async function cartRemoveItem(cartId, lines) {
  return shopifyApi('cartRemoveItem', 'POST', {cartId, lines});
}

async function cartDiscount(cartId, code) {
  return shopifyApi('cartDiscount', 'POST', {cartId, code});
}

async function getCart(cartId) {
  return shopifyApi('getCart', 'POST', {cartId});
}

async function getCheckout(checkoutId) {
  return shopifyApi('getCheckout', 'POST', {checkoutId});
}

async function checkoutCreate(input) {
  return shopifyApi('checkoutCreate', 'POST', {input});
}

async function checkoutAddItems(checkoutId, lineItems) {
  return shopifyApi('checkoutAddItems', 'POST', {checkoutId, lineItems});
}

async function checkoutUpdateItems(checkoutId, lineItems) {
  return shopifyApi('checkoutUpdateItems', 'POST', {checkoutId, lineItems});
}

async function checkoutUpdateEmail(checkoutId, email) {
  return shopifyApi('checkoutUpdateEmail', 'POST', {checkoutId, email});
}

async function checkoutRemoveItems(checkoutId, lineItemIds) {
  return shopifyApi('checkoutRemoveItems', 'POST', {checkoutId, lineItemIds});
}

async function checkoutDiscountAdd(checkoutId, code) {
  return shopifyApi('checkoutDiscountAdd', 'POST', {checkoutId, code});
}

async function checkoutDiscountRemove(checkoutId) {
  return shopifyApi('checkoutDiscountRemove', 'POST', {checkoutId});
}

async function checkoutShippingAddressUpdate(checkoutId, shippingAddress) {
  return shopifyApi('checkoutShippingAddressUpdate', 'POST', {checkoutId, shippingAddress});
}

async function checkoutShippingLineUpdate(checkoutId, shippingRateHandle) {
  return shopifyApi('checkoutShippingLineUpdate', 'POST', {checkoutId, shippingRateHandle});
}

async function checkoutAttributesUpdate(checkoutId, input) {
  return shopifyApi('checkoutAttributesUpdate', 'POST', {checkoutId, input});
}

/**
 * Get selected Products from Shopify
 *
 * getProjects(`${skus.join(' OR ')}`)
 *
 * @param {*} filter
 * @returns
 */
async function getProducts(filter) {
  return shopifyApi('getProducts', 'POST', {filter});
}

async function getVariants(filter) {
  return shopifyApi('getVariants', 'POST', {filter});
}

async function getVariant(id) {
  return shopifyApi('getVariant', 'POST', {id});
}

async function getVariantBySku(sku) {
  return shopifyApi('getVariantBySku', 'POST', {sku});
}

async function draftOrderCreate(input) {
  return shopifyApi('draftOrderCreate', 'POST', {input});
}

async function draftOrderComplete(id, paymentPending) {
  return shopifyApi('draftOrderComplete', 'POST', {id, paymentPending});
}

async function draftOrderUpdate(id, input) {
  return shopifyApi('draftOrderUpdate', 'POST', {id, input});
}

async function draftOrderDuplicate(id) {
  return shopifyApi('draftOrderDuplicate', 'POST', {id});
}

async function draftOrderDelete(input) {
  return shopifyApi('draftOrderDelete', 'POST', {input});
}

async function draftOrderCalculate(input) {
  return shopifyApi('draftOrderCalculate', 'POST', {input});
}

async function getDraftOrders(id, perPage, query, cursor) {
  return shopifyApi('getDraftOrders', 'POST', {id, perPage, query, cursor});
}

async function getDraftOrder(id) {
  return shopifyApi('getDraftOrder', 'POST', {id});
}

async function getOrders(id, perPage, cursor) {
  return shopifyApi('getOrders', 'POST', {id, perPage, cursor});
}

async function getCustomerOrders(perPage, query, cursor) {
  return shopifyApi('getCustomerOrders', 'POST', {perPage, query, cursor});
}

async function getOrder(id) {
  return shopifyApi('getOrder', 'POST', {id});
}

async function getDeliveryLocations() {
  return shopifyApi('getDeliveryLocations', 'POST', {});
}

async function getPickupLocations() {
  return shopifyApi('getPickupLocations', 'POST', {});
}

async function getInventoryItems(filter) {
  return shopifyApi('getInventoryItems', 'POST', {filter});
}

async function addTags(objectId, tags) {
  const token = infoStorage('_loggedIn');
  return shopifyApi('addTags', 'POST', {tags: tags, id: objectId, token: token.value});
}

async function removeTags(objectId, tags) {
  const token = infoStorage('_loggedIn');
  return shopifyApi('removeTags', 'POST', {tags: tags, id: objectId, token: token.value});
}

async function getBestSelling() {
  return shopifyApi('bestSelling', 'POST', {});
}

async function getPaymentTermsTemplates() {
  return shopifyApi('getPaymentTermsTemplates', 'POST', {});
}

async function getMetaObject(handle) {
  return shopifyApi('getMetaObject', 'POST', {handle});
}

async function getMetaObjects(type, perPage, cursor) {
  return shopifyApi('getMetaObjects', 'POST', {type, perPage, cursor});
}

async function getLocation(id) {
  return shopifyApi('getLocation', 'POST', {id});
}

async function findUserByEmail(email) {
  return shopifyApi('findUserByEmail', 'POST', {email});
}

async function sendInviteEmail(id) {
  return shopifyApi('sendInviteEmail', 'POST', {shopifyId: id});
}

export {
    shopifyApi,
    getMetaObject,
    stageUpload,
    fileUpload,
    fileDelete,
    getMedia,
    setMetafield,
    getCompanyMembers,
    searchProducts,
    updateAddress,
    createAddress,
    deleteAddress,
    setDefaultAddress,
    updateUser,
    getUserWishlists,
    associateCustomerToOrder,
    disAssociateCustomerToOrder,
    updateCompanyMember,
    addCompanyMember,
    assignMemberRole,
    deleteCompanyMember,
    deleteCustomer,
    getCompanyLocations,
    forgotPassword,
    cartCreate,
    cartAddItems,
    cartUpdateItem,
    cartRemoveItem,
    cartDiscount,
    getCart,
    checkoutCreate,
    checkoutAddItems,
    checkoutUpdateItems,
    checkoutRemoveItems,
    checkoutUpdateEmail,
    checkoutDiscountAdd,
    checkoutDiscountRemove,
    checkoutShippingAddressUpdate,
    checkoutShippingLineUpdate,
    checkoutAttributesUpdate,
    getCheckout,
    getVariants,
    getVariant,
    getVariantBySku,
    getProducts,
    draftOrderCreate,
    draftOrderComplete,
    draftOrderUpdate,
    draftOrderDuplicate,
    draftOrderCalculate,
    draftOrderDelete,
    getDraftOrders,
    getDraftOrder,
    getOrders,
    getCustomerOrders,
    getOrder,
    getDeliveryLocations,
    getPickupLocations,
    getInventoryItems,
    addTags,
    removeTags,
    getBestSelling,
    getPaymentTermsTemplates,
    getMetaObjects,
    getLocation,
    findUserByEmail,
    sendInviteEmail
};
