import React, { createContext, useEffect, useState } from 'react';
import { registerAccount, initLogin, validateLogin } from '../helpers/auth'
import { getStorage, removeStorage, setStorage, infoStorage } from '../helpers/general'
import { findUserByEmail, sendInviteEmail  } from '../helpers/shopify';
import { navigate } from 'gatsby';

const AuthContext = createContext();

const initialState = {
    userChecked: false,
    isLoggedIn: false,
    customerId: 0,
    email: '',
    support: {},
};

export const AuthProvider = ({ children }) => {
    const [state, setState] = useState(initialState);

    const checkLoggedIn = () => {
        const sessionData = infoStorage('_loggedIn');
        if (sessionData) {
            // console.log(sessionData);
            validateLogin(sessionData).then(response => {
                if (String(response.status).startsWith('2') && response.response.data.token) {
                  if(response.response.data.object.metafields) {
                    const meta = response.response.data.object.metafields
                    const findAllowed = meta.find(item => item.key === 'allow_login');
                    if(findAllowed && findAllowed.value === 'false') {
                        navigate(`/logout?redirect=no-permission`)
                        return;
                    }
                  }


                  setStorage('_loggedIn', response.response.data.token, sessionData.storage === 'sessionStorage' ? true : false);

                  setState({
                    ...state,
                    customerId: response.response.data.object.object.customer.id,
                    email: response.response.data.object.object.customer.email,
                    ...response.response.data.object
                  })

                  // resolve(response)
                } else {
                  clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
                }
              }).catch(error => {
                console.log(error);
                // clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
              })
        } else {
            clearStorage();setState({...state, userChecked: true, onFetchLogin: false})
        }
    }
    // eslint-disable-next-line
    useEffect(() => checkLoggedIn(), []);

    const clearStorage = (fullClear) => {
        removeStorage('_loggedIn')
        removeStorage('_isWholesale')
        removeStorage('_isPending')
        if (fullClear) {
            removeStorage('__jammcd');
            removeStorage('__jammcid');
            removeStorage('__jammut');
            removeStorage('__jammscid');
            removeStorage('__jammqs');
            removeStorage('__jammscc');
            removeStorage('_sellPrice');
        }
    }

    const addSupport = () => {};
    const refreshData = () => {};

    const login = async (username, password, redirect = true, remember = false) => {
        // console.log(username, password);
        // const response = await authenticateUser(username, password);
        const response = await initLogin(username, password);
        // Do something if needed

        if (String(response.status).startsWith('2') && response.response.data.token) {
            const prevData = JSON.parse(getStorage('__jammcid' ?? 'null'))
            if(prevData && prevData.email !== username) {
                clearStorage(true);
            }
            setStorage('_loggedIn', response.response.data.token, remember ? false : true);


            // setState({
            //   ...state,
            //   ...response.response.object
            // })

            await afterAuth(redirect);
          }

        return response;
    };

    const attemptActivateUser = async (email) => {
        if(!email) return {error: 'Email is required', result: false};
        
        const userInfo = await findUserByEmail(email);

        if(userInfo.response?.data?.customers?.edges?.length === 0) {
            return {
                error: 'User not found',
                result: false
            };
        }

        // Shopify returns a contain function so we need to compare again with the exact email
        const exactUser = userInfo.response.data.customers.edges.find(user => user.node.email === email);
        const userId = exactUser.node.id;
        const splitUserId = userId.split('/');
    
        if(splitUserId.length !== 5) {
            return {
                error: 'Unable to check customer id',
                result: false
            };
        }
    
        const shopifyId = splitUserId[4];
        const response = await sendInviteEmail(shopifyId);

        if(response.status === 422) {
            let error = response?.response?.errors?.customer_id[0] || 'Error sending email: contact support';

            if(error.includes('already enabled')) {
                error = error + ' - Please use forgot password';
            }

            return {
                error: error.charAt(0).toUpperCase() + error.slice(1),
                result: false
            };
        } else if(`${response.status}`[0] !== '2') {
            return {
                error: 'Error sending email: contact support',
                result: false
            };
        } else {
            return {
                error: false,
                result: true
            };
        }
    }

    const afterAuth = async (redirect = true) => {
        const afterAuthCalls = getStorage('_afterAuth');
        let actionResult = false;

        if (afterAuthCalls) {
        //   const afterAuthCallsObject = JSON.parse(afterAuthCalls);
        //   if (afterAuthCallsObject.action === 'saveWishlist') {
        //     // console.log("_afterAuth should be removed");
        //     removeStorage('_afterAuth')
        //     actionResult = await state.support.wishlist.saveWishlist(afterAuthCallsObject.name, true, customerId)
        //   }
        }

        if (typeof window !== 'undefined' && typeof document !== 'undefined' && !actionResult && redirect) {
          console.log("Redirecting normally");
          const browserLastPage = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
          const userLastPage = getStorage('lastPage');
          const forcedPage = getStorage('forcedPage');
          const returnUrl = forcedPage || userLastPage || browserLastPage;
          removeStorage('forcedPage');
          window.location = returnUrl;
        }

        return actionResult
    }

    const logout = () => {
        setState(initialState)
        clearStorage(true);

        if (typeof window !== 'undefined') {
            //get query string
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const redirect = urlParams.get('redirect');
            window.location = redirect ? `/${redirect}` :  '/';
        }
    }

    const signup = async (customerObject) => {
        // const response = await createUser(customerObject);
        const response = await registerAccount(customerObject);
        // Do something if needed
        // console.log(response);
        return response;
    };

    const checkPassword = async (username, password) => {
        const attemptLogin = await initLogin(username, password);
        // Do something if needed
        // console.log(attemptLogin);

        if (String(attemptLogin.status).startsWith('2') && attemptLogin.response.data.token) {
            return attemptLogin.response.data;
        } else {
            return {errors: 'Password not correct'}
        }
    };

    const forgotPassword = false;
    const changePassword = false;

    return (
        <AuthContext.Provider value={{
            state,
            setState,
            addSupport,
            refreshData,
            login,
            logout,
            checkLoggedIn,
            signup,
            checkPassword,
            attemptActivateUser,
            forgotPassword,
            changePassword
        }}>{children}</AuthContext.Provider>
    );
};

export default AuthContext;