// import axios from 'axios';
import { useState, useEffect } from 'react';
import { celigoApi } from '../celigo';
// import { navigate } from 'gatsby'
// import { useRouter } from 'next/router'

/**
 * Track recently viewed products
 *
 * Handles tracking of product views and stores product IDs in session for display on the site
 *
 * @param   {Number} productId       The prodoct ID to track
 *
    import { trackRV } from '../helpers/general'

    trackRV(productId).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
function trackRV(productId) {
  const sessionKey = '__trackRV';
  const displayAmount = 10;

  if (typeof sessionStorage !== 'undefined') {
    const recentlyViewed = getStorage(sessionKey);
    let recentlyViewedArr = [];

    if (recentlyViewed !== null) {
      recentlyViewedArr = JSON.parse(recentlyViewed);
    }

    const existIndex = recentlyViewedArr.indexOf(productId);

    if (existIndex > -1) {
      recentlyViewedArr.splice(existIndex, 1);
    }

    recentlyViewedArr.unshift(productId);

    if (recentlyViewedArr.length > displayAmount) {
      recentlyViewedArr.pop();
    }

    setStorage(sessionKey, JSON.stringify(recentlyViewedArr), true);
  }
}

/**
 * Get local / session / cookie storage information
 *
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return { storage: 'sessionStorage', value: sessionValue };
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return { storage: 'localStorage', value: localValue };
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 *
 * Handles browser storage information functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return { storage: 'cookie', value: c.substring(name.length, c.length) };
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 *
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 *
 * Handles browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 *
 * Handles setting browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 *
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 *
 * Handles setting browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 *
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire) {
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }
}

/**
 * Remove local / session / cookie storage
 *
 * Handles removing browser storage functionality. Falls back to using cookies if required
 *
 * @param   {String} key    The key used to set the value under
 *
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 *
 * Handles removing browser storage functionality via cookies
 *
 * @param   {String} key    The key used to set the value under
 *
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 *
 * Checks the provided email address and validates its format
 *
 * @param   {String} email  The email address
 *
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Validate strong password format
 *
 *
 * @param   {String} password  The password
 *
    import { validateStrongPassword } from '../helpers/general'

    validateStrongPassword(email)
 */
function validateStrongPassword(password) {
  return /(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(password);
}

/**
 * Validate phone format
 *
 * Checks the provided phone number and validates its format
 *
 * @param   {String} number  The phone number
 *
    import { validatePhoneNumber } from '../helpers/general'

    validatePhoneNumber(number)
 */
function validatePhoneNumber(number) {
  const regex = /^[- +()]*[0-9][- +()0-9]*$/;

  return regex.test(number);
}

/**
 * Is value numeric
 *
 * Determine whether variable is a number
 *
 * @param {*} str
 *
    import { isNumeric } from '../helpers/general'

    isNumeric(value)
 */
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false; // we only process strings and numbers!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

/**
 * Is value an object
 *
 * Determine whether variable is an object
 *
 * @param {*} obj
 *
    import { isObject } from '../helpers/general'

    isObject(obj)
 */
function isObject(obj) {
  return Array.isArray(obj) === false && typeof obj === 'object';
}

/**
 * Format price
 *
 * Format number into local currency output
 *
 * @param {Number} amount
 * @param {String} currency
 * @param {Boolean} appendZero
 *
    import { formatPrice } from '../helpers/general'

    formatPrice(value, "USD", true)
 */
function formatPrice(amount, currency = 'AUD', appendZero = false) {
  let displayAmount =
    (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) ||
    amount;
  /* Set language display */
  const languageCode =
    typeof window !== 'undefined'
      ? window.navigator.language || 'en-AU'
      : 'en-AU';

  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency,
  });
  let formattedPrice = formatObject.format(displayAmount);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(
      (obj) => obj.type === 'currency'
    );
    const decimalValue = formattedPriceParts.find(
      (obj) => obj.type === 'fraction'
    );
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  }

  return formattedPrice;
}

/**
 * Get ordinal for date number
 *
 * Given a date number, this will return the suffix for a date
 *
 * @param   {Number} n  The date
 *
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 3
    ? 'rd'
    : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Get slug of the product from given uri
 *
 * @param {string} uri the url of the product
 * example: http://your-store-url.mybigcommerce.com/your-product/
 * @returns {string} slug of the product
 * example: /product/your-product/
 */
function getUriFromURL(urlStr = '') {
  const slug = urlStr
    .split('/')
    .filter((x) => x)
    .pop();
  if (slug) {
    return `/product/${slug}/`;
  }
  return '';
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Fetch title
 *
 * Determine which title for a product to use
 *
 * @param   {string} bcData   The BC product title
 * @param   {string} otherData   The string to split by
 *
 * import { fetchTitle } from '../helpers/general'
 *
 * fetchTitle(bcData, otherData)
 *
 * @returns {string} title
 */
function fetchTitle(bcData, otherData) {
  let title = '';
  /* Add logic here if there are other sources of the product name */
  if (otherData && otherData.name) {
    title = otherData.name;
  } else if (bcData.name !== undefined) {
    const splitTitle = dissectTitle(bcData.name, ' (');
    title = splitTitle[0];
  }

  return title;
}

/**
 * Dissect title
 *
 * Split out title to reveal the design type from the product name
 *
 * @param   {string} productTitle   The product title
 * @param   {string} splitBy        The string to split by
 *
 * import { dissectTitle } from '../helpers/general'
 *
 * dissectTitle(productTitle, splitBy)
 *
 * @returns {array} splitTitle
 */
function dissectTitle(productTitle, splitBy) {
  let titleParts = [''];
  if (productTitle !== undefined) {
    titleParts = productTitle.split(splitBy);
  }

  return titleParts;
}

/**
 * Capitalise first letter
 *
 * Capitalise the first letter in the provided string
 *
 * @param   {string} string   The string
 *
 * import { ucFirst } from '../helpers/general'
 *
 * ucFirst(string)
 *
 * @returns {string} the capitalised string
 */
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 *
 * Provide on the fly window dimensions
 *
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 *
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading =
        window.scriptsLoading &&
        window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => {
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        };
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback]);
}

/**
 * Decode unicode characters
 *
 * Provides a way to decode a string containing unicode characters for logic purposes
 *
    import { decodeUnicode } from '../helpers/general'

    decodeUnicode(content);
 */
function decodeUnicode(text) {
  var map = {
    '&amp;': '&',
    '&#038;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#039;': "'",
    '&#8217;': "'",
    '&#8216;': "'",
    '&#8211;': '-',
    '&#8212;': '-',
    '&#8230;': '…',
    '&#8221;': '"',
    '&#8243;': '"',
  };
  // console.log("Decoding", text);
  return text.replace(/&[\w\d#]{2,5};/g, function (m) {
    return map[m];
  });
}

function rewriteShippingOptionLabel(label, hideType = false) {
  let modLabel = label;
  /* Write logic to rename shipping option labels from BC */
  return modLabel;
}

/**
 * relocate
 *
 * An agnostic function that is configured to support the framework being used, with a fallback
 *
    import { relocate } from '../helpers/general'

    relocate('/path');
 */
function relocate(path) {
  /**
   * GATSBY Routing
   */
  // if (process.env.GATSBY_ENABLED && !navigate) {
  //   console.error("** MODIFY helpers/general to enable Gatsby's Navigate function **");
  //   return;
  // }
  // if (navigate) {
  //   navigate(path);
  //   return;
  // }

  /**
   * NEXTJS Routing
   * This is an invalid way of utilising useRouter in NextJS. Look to use useRouter directly in the page using this function.
   *
     import { useRouter } from 'next/router';
     const relocate = useRouter();
     relocate.push(path)
   */
  // if (process.env.NEXT_PIBLIC_ENABLED && !useRouter) {
  //   console.error("** MODIFY helpers/general to enable NextJS's useRouter function **");
  //   return;
  // }
  // if (useRouter) {
  //   const router = useRouter();
  //   router.push(path);
  //   return;
  // }

  console.warn(
    'Standard window.location functionality used to route the user. Consider enabling the frameworks method.'
  );
  window.location = path;
  return;
}

/**
 * dataLayerPush
 *
 * Enables a data push connection to a connected GTM container
 *
    import { dataLayerPush } from '../helpers/general'

    dataLayerPush(eventName, {extraVariable: 'value'}, productData, linkToNavigate);

    Predefined event names:
      - SearchResults: sends list of products listed in the search result
        eg: dataLayerPush('SearchResults', null, productObject);

      - AllProductsOnPage: sends list of products listed on the current page (PLP, PDP, etc)
        eg: dataLayerPush('AllProducts On Page', null, productObject);

      - AddedToWishlist: sends product that was added to the wishlist
        eg: dataLayerPush('AddedToWishlist', null, productObject);

      - ProductClick: sets up enhanced ecomm tracking when a product is clicked to be viewed
        eg: dataLayerPush('ProductClick', null, productObject, path);

      - ProductView: sets up enhanced ecomm tracking when viewing a product page
        eg: dataLayerPush('ProductView', null, productObject);

      - AddedToCart: sets up enhanced ecomm tracking when adding a product (or products) to cart
        eg: dataLayerPush('AddedToCart', null, productObject);

      - RemovedFromCart: sets up enhanced ecomm tracking when removing a product (or products) to cart
        eg: dataLayerPush('RemovedFromCart', null, productObject);

      - Checkout: sends checkout data to track prior to payment
        eg: dataLayerPush('Checkout', null, checkoutObject, '/checkout/');
 */
function dataLayerPush(eventName, extraData, productData, linkTo) {
  // Ensure window is available before moving forward
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // console.log('dataLayer Data', extraData, productData, linkTo);

    const object = {
      ...extraData,
      event: eventName,
    };

    let productsObject = false;

    const productObject = (product) => ({
      id:
        product.entityId ||
        product.entity_id ||
        product.productId ||
        product.product_id ||
        product.id ||
        0,
      name: product.title || product.name || '',
      price: product.price || 0,
      currency: 'AUD',
      // category: product.categories,
      quantity: product.quantity || 1,
    });

    if (productData) {
      const products = !Array.isArray(productData)
        ? [productData]
        : productData;
      productsObject = [...products.map((product) => productObject(product))];
    }

    switch (eventName) {
      case 'SearchResults':
      case 'AllProductsOnPage':
        if (productsObject) {
          object.products = productsObject;
        }
        break;

      case 'ProductClick':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            click: {
              products: productsObject,
            },
          };
        }
        break;

      case 'ProductView':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            detail: {
              products: productsObject,
            },
          };
        }
        break;

      case 'AddedToCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            currencyCode: 'AUD',
            add: { products: productsObject },
          };
        }
        break;

      case 'RemovedFromCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            remove: {
              products: productsObject,
            },
          };
        }
        break;

      case 'Checkout':
        if (productsObject) {
          object.checkout = {
            totalAmount: productsObject.grandTotal,
            tax: productsObject.taxTotal,
            shipping: productsObject.shippingCostTotal,
            products: [],
          };

          const prodTypes = Object.keys(productsObject.cart.lineItems);
          prodTypes.map((type) => {
            if (productsObject.cart.lineItems[type].length > 0) {
              productsObject.cart.lineItems[type].map((item) => {
                const itemId = `${item.productId}_${item.variantId}`;
                const itemName = `${item.name} (${item.sku})`;
                const product = productObject({
                  id: itemId,
                  title: itemName,
                  price: item.salePrice,
                  quantity: item.quantity,
                });
                object.checkout.products.push(product);
                return true;
              });
            }
            return true;
          });
        }
        break;

      default:
        // do nothing
        break;
    }

    // Check if GTM is loaded
    if (linkTo) {
      if (typeof window.google_tag_manager !== 'undefined') {
        object.eventCallback = () => {
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else {
            relocate(linkTo);
          }
        };
      } else {
        // Handler for when GTM is not present
        if (linkTo.startsWith('http')) {
          document.location = linkTo;
        } else {
          relocate(linkTo);
        }
      }
    }

    // console.log('Pushing DataLayer: ', object);
    window.dataLayer.push(object);
  }
}

/**
 * urlHandler
 *
 * An agnostic function that is configured to support various objects being used, to return the found URL path
 *
    import { urlHandler } from '../helpers/general'

    urlHandler(product);
 */
function urlHandler(object) {
  /* Bigcommerce */
  if ('custom_url' in object) return object.custom_url.url;

  /* Shopify */
  if ('handle' in object) return `/${object.handle}/`;

  /* BC GraphQL */
  if ('path' in object) return object.path;

  /* None found */
  return false;
}

/**
 * resolved link
 *
 * A utility function that appends the slash character to the start of a string if it doesn't already exist given the url does not start with an http or https
 *
    import { appendSlashToUrl } from '../helpers/general'

    appendSlashToUrl(url);
 */
function appendSlashToUrl(url) {
  if (typeof url !== 'undefined') {
    if (
      url.startsWith('http') ||
      url.startsWith('https') ||
      url.startsWith('/')
    ) {
      return url;
    }
    return `/${url}`;
  }
  return '/';
}

/**
 * formats a Wordpress date to a readable format
 *
 *
 *
    import { formatArticleDate } from '../helpers/general'

    formatArticleDate(date);
 */
function formatArticleDate(date) {
  const dateObj = new Date(date);
  const formattedDate = `${dateObj.toLocaleString('default', {
    month: 'long',
  })} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  return formattedDate;
}

/**
 * Prepare product object for product card
 *
    import { prepProductObject } from '../helpers/general'

    prepProductObject(productObject);
 */
function prepProductObject(obj) {
  const product = { ...obj.node };
  // Sku
  product.sku = product.variants.edges[0].node.sku;
  // Images
  product.images = [];
  product.images.push({
    image: { src: product.variants.edges[0].node.image?.src },
  });
  // Variants
  product.variants = product.variants.edges.map((v) => v.node);
  return product;
}

/**
 * Converts a graphql product object to a corresponding product card object. Unwraps the graphql object
 *
    import { prepProductCard } from '../helpers/general'

    prepProductCard(productList);
 */
function prepProductCard(shopifyProductList) {
  const formattedNewProducts = shopifyProductList.map(({ node }) => {
    // map variant
    const variantMap = node.variants.map((v) => {
      return {
        options: [{ name: 'Colour', value: v.title }],
        price: v.price,
        metafields: v.metafields,
      };
    });

    // get sku from metafields
    const sku = node.metafields.find((m) => m.key === 'SKU')?.value;

    return {
      title: node.title,
      handle: node.handle,
      metafields: node.metafields,
      price: node.priceRangeV2.minVariantPrice.amount,
      tags: node.tags,
      variants: variantMap,
      isShopify: true,
      sku: sku,
      images: [{ image: { src: node.featuredImage?.src } }],
    };
  });

  return formattedNewProducts;
}

/**
 * Converts external links to open in a new tab
 *
    import { replaceLinksFromHtmlString } from '../helpers/general'

    replaceLinksFromHtmlString(htmlString, targetDomains);
 */
function replaceLinksFromHtmlString(htmlString) {
  // links not here will be opened in a new tab
  // should be configured in env variable once deployed
  const targetDomains = ['furnlink.com.au', 'www.furnlink.com'];

  const isMatchingDomain = (url, targetDomain) => {
    const parser = new URL(url);
    return targetDomain.includes(parser.hostname);
  };

  const regex = /<a\s+([^>]*)href=["']([^"']*)["']([^>]*)>/g;
  const modifiedHtml = htmlString.replace(
    regex,
    function (match, beforeHref, hrefValue, afterHref) {
      // Check if the hrefValue matches the target domain
      if (isMatchingDomain(hrefValue, targetDomains)) {
        // If it's not a match, leave it unchanged
        return match;
      } else {
        // If it's a match, add target="_blank"
        return (
          '<a ' +
          beforeHref +
          'href="' +
          hrefValue +
          '" target="_blank"' +
          afterHref +
          '>'
        );
      }
    }
  );

  return modifiedHtml;
}

const isRectangular = (image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      resolve(aspectRatio > 1); // Adjust this threshold as needed
    };
    img.onerror = reject;
    img.src = image.image.src;
  });
};

const cleansePriceLevel = (priceLevel) => {
  if (!priceLevel) return;
  const newPriceLevel = priceLevel
    .replaceAll(' ', '_')
    .replaceAll('+', 'plus')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('_Price', '')
    .toLowerCase();
  return newPriceLevel;
};

/**
 *  Update anchor targets
 * @param {*} htmlString  HTML string to update
 * @returns  {string}  Updated HTML string
 */
function updateAnchorTargets(htmlString) {
  // Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Find all anchor tags
  const anchors = doc.querySelectorAll('a');

  // Iterate over each anchor tag
  anchors.forEach((anchor) => {
    const href = anchor.getAttribute('href');
    // Check if href contains 'https'
    if (href && href.startsWith('https')) {
      // Change target to '_blank'
      anchor.setAttribute('target', '_blank');
    }
  });

  // Return the modified HTML as a string
  return doc.body.innerHTML;
}

function getBase64(file) {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const SORT_OPTIONS = [
  { label: 'Order status', value: '' },
  {
    label: 'Billed',
    value: 'SalesOrd:G',
  },
  {
    label: 'Cancelled',
    value: 'SalesOrd:C',
  },
  {
    label: 'Closed',
    value: 'SalesOrd:H',
  },
  {
    label: 'Partially Fulfilled',
    value: 'SalesOrd:D',
  },
  {
    label: 'Pending Approval',
    value: 'SalesOrd:A',
  },
  {
    label: 'Pending Billing',
    value: 'SalesOrd:F',
  },
  {
    label: 'Pending Billing/Partially Fulfilled',
    value: 'SalesOrd:E',
  },
  {
    label: 'Pending Fulfillment',
    value: 'SalesOrd:B',
  },
];

export const DELIVERY_OPTIONS = [
  {label: 'Delivery', value: 'delivery'},
  {label: 'Collect from Warehouse', value: 'collect'},
];


const celigoFetcher = (url, payload) => celigoApi(url ,'POST', payload).then(res => res)

function isValidPhoneNumber(phoneNumber) {
  // Regular expression for Australian phone numbers
  const regex = /(^1300\d{6}$)|(^1800|1900|1902\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\d{4}$)|(^04\d{2,3}\d{6}$)/;

  // Test the phone number against the regex
  return regex.test(phoneNumber);
}


function removeCheckoutCookies() {
  removeStorage('__jammqs');
  removeStorage('__jammscidr');
  removeStorage('__jammcidr');
  removeStorage('__jammsccr');
  removeStorage('__jammscid');
  removeStorage('__jammcid');
  removeStorage('__jammscc');
}

function isValidJson (string) {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
}

function parsedMetaData(pricesMeta) {
  let metaData = null;
  if (pricesMeta && pricesMeta.value) {
    if (isValidJson(pricesMeta?.value) && !isValidJson(JSON.parse(pricesMeta?.value))) {
      metaData = JSON.parse(pricesMeta?.value);
    } else if (isValidJson(JSON.parse(pricesMeta?.value))) {
      metaData =  JSON.parse(JSON.parse(pricesMeta?.value));
    }
  }
  return metaData;
}

export {
  celigoFetcher,
  isValidPhoneNumber,
  updateAnchorTargets,
  isRectangular,
  trackRV,
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  validateStrongPassword,
  validatePhoneNumber,
  isNumeric,
  isObject,
  formatPrice,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  ucFirst,
  getUriFromURL,
  fetchTitle,
  dissectTitle,
  useWindowDimensions,
  useScript,
  decodeUnicode,
  rewriteShippingOptionLabel,
  relocate,
  dataLayerPush,
  urlHandler,
  appendSlashToUrl,
  formatArticleDate,
  prepProductObject,
  prepProductCard,
  replaceLinksFromHtmlString,
  cleansePriceLevel,
  removeCheckoutCookies,
  getBase64,
  isValidJson,
  parsedMetaData
};

