import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { getUserWishlists, setMetafield, getProducts, getInventoryItems } from '../helpers/shopify';
import AuthContext from './AuthProvider';
import CartContext from './CartProvider';
import { getStorage, setStorage } from '../helpers/general';

const WishlistContext = createContext();

const initialState = {
  sessionWishlistSkus: null,
  sessionWishlistIdCount: 0,
  sessionWishlistData: [],
  fetchRetries: 5,
  userExistingWishlistName: [],
};

initialState.sessionWishlistSkus = JSON.parse(getStorage('sessionWishlistSkus')) || null;
initialState.sessionWishlistIdCount = initialState.sessionWishlistSkus?.length || 0;

export const WishlistProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const customerData = auth && auth.state;
  const defaultMarkup = customerData.defaultMarkup;
  const cart = useContext(CartContext);
  const addToCart = cart && cart.addToCart;
  const [init, setInit] = useState(false);
  const [sessionWishlistSkus, setSessionWishlistSkus] = useState(initialState.sessionWishlistSkus);

  const [wishlistData, setWishlistData] = useState(null);

  /*
  Example wishlist schema
  [{
    id: 1,
    name: 'My Wishlist',
    products: ['sku1', 'sku2']
  }]
  */

  const existsInWishlist = (sku) => {
    const currentWishlist = sessionWishlistSkus;

    let existing = false;
    let existingIndex = null;

    currentWishlist.map((s, skuIndex) => {
      if (s === sku) {
        existing = true;
        existingIndex = skuIndex;
      }

      return true;
    });

    return { active: existing, index: existingIndex };
  };

  const listWishlists = useCallback(() => {
    if (!wishlistData) {
      try {
        return getUserWishlists().then(response => {
          if (String(response.status).startsWith('2')) {
            const fetchedWishlists = get(response.response, 'data.customer.wishlists.value', null);
            const wishlists = JSON.parse(fetchedWishlists) || [];
            setWishlistData(wishlists);
            return wishlists;
          } else {
            console.log('Wishlist fetch failed', response);
            return []
          }
        });
      } catch (e) {
        console.log('Wishlist fetch failed', e);
        return []
      }
    } else {
      return wishlistData;
    }
  }, [wishlistData])

  const populateSessionData = useCallback(async (predefinedWishlists) => {
    const wishlists = predefinedWishlists || await listWishlists();
    const _wishlistSkus = [];
    wishlists.map(w => {_wishlistSkus.push(...w.products); return true;});
    const wishlistSkus = [...new Set(_wishlistSkus)];
    setSessionWishlistSkus(wishlistSkus);
    setStorage('sessionWishlistSkus', JSON.stringify(wishlistSkus));
  }, [listWishlists]);

  const getWishlist = async (id) => {
    const wishlists = await listWishlists();
    const listIndex = wishlists.findIndex(w => Number(id) === w.id);
    const wishlist = {...wishlists[listIndex]};
    let _productData = [];

    if(wishlist.products.length) _productData = await getProducts(`${wishlist.products.join(' OR ')}`);

    const productData = get(_productData, 'response.data.products.edges', []);
    wishlist.productData = productData.map(p => {
      const product = { ...p.node };
      // Sku
      product.sku = product.variants.edges[0].node.sku;
      // Images
      product.images = [];
      product.images.push({image: {src: product.variants.edges[0].node.image?.src}});
      // Variants
      product.variants = product.variants.edges.map(v => v.node);
      return product;
    });
    return wishlist;
  }

  const createWishlist = (name, sku) => {
    const wishlists = listWishlists();
    // get latest id
    let latestWishlistId = 0;
    wishlists.map(w => {
      const thisId = Number(w.id);
      if (thisId > latestWishlistId) {
        latestWishlistId = thisId;
      }

      return true;
    });

    // increment id
    latestWishlistId++;

    // create object
    const newWishlist = {
      id: latestWishlistId,
      created: new Date(),
      name,
      products: []
    }
    if (sku) {
      newWishlist.products.push(sku);
    }

    // Add to wishlist array
    const modifyWishlistData = [...wishlists];
    modifyWishlistData.push(newWishlist);
    setWishlistData(modifyWishlistData);
    populateSessionData(modifyWishlistData);

    // Store
    const metafield = {
      key: 'wishlist',
      namespace: 'custom',
      ownerId: customerData.customerId,
      value: JSON.stringify(modifyWishlistData),
      type: 'json'
    }

    return setMetafield(metafield);
  }

  const deleteWishlist = (id) => {
    const wishlists = listWishlists();
    const modifyWishlistData = [...wishlists];
    const listIndex = modifyWishlistData.findIndex(w => Number(id) === w.id);
    modifyWishlistData.splice(listIndex, 1);
    setWishlistData(modifyWishlistData);
    populateSessionData(modifyWishlistData);

    // Store
    const metafield = {
      key: 'wishlist',
      namespace: 'custom',
      ownerId: customerData.customerId,
      value: JSON.stringify(modifyWishlistData),
      type: 'json'
    }

    return setMetafield(metafield);
  }

  const addToWishlist = (id, sku) => {
    const wishlists = listWishlists();
    const modifyWishlistData = [...wishlists];
    const listIndex = modifyWishlistData.findIndex(w => Number(id) === w.id);
    const currentProducts = modifyWishlistData[listIndex].products.filter(p => p); /* Clear out nulls */
    currentProducts.push(sku);
    modifyWishlistData[listIndex].products = [...new Set(currentProducts)];
    setWishlistData(modifyWishlistData);
    populateSessionData(modifyWishlistData);

    // Store
    const metafield = {
      key: 'wishlist',
      namespace: 'custom',
      ownerId: customerData.customerId,
      value: JSON.stringify(modifyWishlistData),
      type: 'json'
    }

    return setMetafield(metafield);
  }

  const removeFromWishlist = (id, sku) => {
    const wishlists = listWishlists();
    const modifyWishlistData = [...wishlists];
    const listIndex = modifyWishlistData.findIndex(w => Number(id) === w.id);
    const currentProducts = modifyWishlistData[listIndex].products;
    const productIndex = currentProducts.indexOf(sku);
    currentProducts.splice(productIndex, 1);
    modifyWishlistData[listIndex].products = [...new Set(currentProducts)];
    setWishlistData(modifyWishlistData);
    populateSessionData(modifyWishlistData);

    // Store
    const metafield = {
      key: 'wishlist',
      namespace: 'custom',
      ownerId: customerData.customerId,
      value: JSON.stringify(modifyWishlistData),
      type: 'json'
    }

    return setMetafield(metafield);
  }

  const addWishlistToCart = async (id) => {
    const wishlist = await getWishlist(id);
    const inventoryLevels = await getInventoryItems(`sku:${wishlist.productData[0].variants[0].sku}`);
    const inventoryItem = inventoryLevels.response.data.inventoryItems.edges.length ? inventoryLevels.response.data.inventoryItems.edges[0].node : [];
    const defaultLocation = inventoryItem.inventoryLevels.edges.length ? inventoryItem.inventoryLevels.edges.find(inventory => inventory.node.location.name.includes('Bundaberg')) : [];
    const defaultLocationId = defaultLocation.node.location.id;
    const defaultLocationName = defaultLocation.node.location.name;

    const lineItems = wishlist.productData.map(p => ({
      variantId: p.variants[0].entityId,
      quantity: 1,
      customAttributes: [
        {
          key: '_markup',
          value: defaultMarkup
        },
        {
          key: '_locationName',
          value: defaultLocationName,
        },
        {
          key: '_locationId',
          value: defaultLocationId
        },
        {
          key: '_inventoryItemId',
          value: inventoryItem.id,
        }
    ]
    }));

    addToCart(lineItems, null, 'reseller', null);
  }

  useEffect(() => {
    if (!sessionWishlistSkus && !init) {
      populateSessionData();
      setInit(true);
    }
  }, [sessionWishlistSkus, init, populateSessionData]);

  return (
    <WishlistContext.Provider
      value={{
        sessionWishlistSkus,
        wishlistData,
        listWishlists,
        existsInWishlist,
        getWishlist,
        createWishlist,
        deleteWishlist,
        addToWishlist,
        removeFromWishlist,
        addWishlistToCart
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistContext;
