import React from 'react';

// import { StoreProvider } from './src/context/StoreProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
// import { PriceProvider } from './src/context/PriceProvider';
import { NotificationProvider } from './src/context/NotificationProvider';
import { CartProvider } from './src/context/CartProvider';
import { BindProvider } from './src/context/BindProvider';
import { StoryblokProvider } from './src/context/StoryblokProvider';
import { setStorage, getStorage } from './src/helpers/general';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => (
      <AuthProvider>
        {/* <PriceProvider> */}
        <NotificationProvider>
          <StoryblokProvider>
            <CartProvider>
              <WishlistProvider>
                <BindProvider>
                  {element}
                </BindProvider>
              </WishlistProvider>
            </CartProvider>
          </StoryblokProvider>
        </NotificationProvider>
        {/* </PriceProvider> */}
      </AuthProvider>
);

export const onClientEntry = () => {
  const maintenanceKey = getStorage('_fmk');
  if (process.env.SITE_MAINTENANCE === "true" && window.location.pathname !== '/maintenance' && window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') === -1 && maintenanceKey !== '92ef3ed2ca050dc623ed949ec04ccb56') {
    window.location = '/maintenance';
  } else if (window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') > -1) {
    setStorage('_fmk', '92ef3ed2ca050dc623ed949ec04ccb56');
  }
}