import React, { createContext, useCallback, useEffect, useState, useContext } from 'react';
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import _, {get, sumBy} from "lodash"
import { cleansePriceLevel, getStorage, removeCheckoutCookies, setStorage, parsedMetaData } from '../helpers/general'; // dataLayerPush, removeStorage
import { checkoutCreate, checkoutAddItems, checkoutUpdateItems, getCheckout, checkoutRemoveItems, checkoutUpdateEmail, checkoutDiscountAdd, checkoutDiscountRemove, checkoutShippingAddressUpdate, checkoutShippingLineUpdate, draftOrderCreate, draftOrderUpdate, getDeliveryLocations, getPickupLocations, getDraftOrders, getOrders, getCustomerOrders, checkoutAttributesUpdate, draftOrderDelete, getPaymentTermsTemplates, draftOrderComplete, getDraftOrder } from '../helpers/shopify';
import AuthContext from './AuthProvider';
import { calculateProductsPackage } from '../helpers/converted';

const CartContext = createContext();

const initialCartState = {
  id: null,
  currency: {
    code: 'USD'
  },
  cartAmount: 0,
  lineItems: [],
  numberItems: 0,
  redirectUrls: {}
};
const initialState = {
    cartLoading: false,
    cartFetched: false,
    cartError: false,
    cart: initialCartState,
    shippingMethod: 'delivery',
    pickupLocations: false,
    checkoutOption: false,
};

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN
}, fetch);

const storeKey = '__jammscid';
const checkoutStoreKey = '__jammcid';
const storeCartCount = '__jammscc';

export const CartProvider = ({ children }) => {
    const auth = useContext(AuthContext);
    const email = auth && auth.state?.object?.customer?.email;
    const priceLevelCtx = auth && auth.state?.priceLevel;
    // const usrPriceLvl = getStorage('__jammUsrLvl') || cleansePriceLevel(priceLevelCtx);
    const customerId = auth && auth.state?.object?.customer?.id;
    const companyId = auth.state?.object?.customer?.companyContactProfiles && auth.state?.object?.customer?.companyContactProfiles[0].company?.id ? auth.state?.object?.customer?.companyContactProfiles[0].company?.id : false;
    const companyContactId = auth.state?.object?.customer?.companyContactProfiles && auth.state?.object?.customer?.companyContactProfiles[0]?.company?.contacts?.edges[0]?.node?.id;
    const companyLocationId = auth && auth.state?.object?.customer?.companyContactProfiles && auth.state?.object?.customer?.companyContactProfiles[0]?.company?.locations?.edges[0]?.node?.id;
    const [state, setState] = useState(initialState);
    const [notifications, updateNotifications] = useState([]);
    const cartctx = state.cart;
    const { id } = cartctx; // cartSubtotalAmount
    const stateCartId = id;

    const addNotification = (text, type = 'success') => {
      updateNotifications([...notifications, { text, type, id: Date.now(), btn: 'cart' }]);
    };

    const removeNotification = id => {
      updateNotifications(notifications.filter(ntfy => ntfy.id !== id));
    };

    const calculateDiscounts = useCallback((cartObj, lineItemsTotalAmountReseller) => {
      const percentageDiscount = parseFloat(cartObj.discountApplications.edges[0].node.value.percentage) || 0;
      const amountDiscount = parseFloat(cartObj.discountApplications.edges[0].node.value.amount) || 0;
      let totalDiscount = 0;
      let discountCode = false;
      if (percentageDiscount !== 0 && amountDiscount === 0) {
        totalDiscount = lineItemsTotalAmountReseller * (percentageDiscount / 100) || 0;
        discountCode = `${percentageDiscount}% off`;
      } else if (percentageDiscount === 0 && amountDiscount !== 0) {
        totalDiscount = amountDiscount || 0;
        discountCode = `$${amountDiscount} off`;
      }
      const coupons = [{
        code: discountCode,
        discounted_amount: parseFloat(totalDiscount)
      }];
      return coupons;
    }, []);

    const setupCheckoutProducts = async (cart, isResellerCart, isProceedToSaveQuote) => {

        const promises = cart.lineItems.map(async lineItem => {
          const productData = {
            variantId: lineItem.variant.id,
            quantity: lineItem.quantity,
            customAttributes: lineItem.customAttributes
          }

          // Service items
          const powderCoating = lineItem.customAttributes.find(attr => attr.key === 'powderCoating');
          const powderCoatingValue = powderCoating ? parseFloat(powderCoating.value.split(':')[0]) : 0;
          const assembly = lineItem.customAttributes.find(attr => attr.key === 'assemblyService');
          const assemblyValue = assembly ? parseFloat(assembly.value) : 0;
          const cutdown = lineItem.customAttributes.find(attr => attr.key === 'cutdownService');
          const cutdownValue = cutdown ? parseFloat(cutdown.value.split(':')[0]) : 0;
          const substrate = lineItem.customAttributes.find(attr => attr.key === 'substrate');
          const substrateValue = substrate ? parseFloat(substrate.value.split(':')[0]) : 0;
          const serviceCost = powderCoatingValue + assemblyValue + cutdownValue + substrateValue;

          if ((powderCoating || assembly || cutdown || substrate) && !isProceedToSaveQuote) {
            delete productData.variantId;

            const costPrice = lineItem.quantity > 49  && lineItem.variant.priceLevel50plus ? lineItem.variant.priceLevel50plus : lineItem.variant.priceLevel ? lineItem.variant.priceLevel : parseFloat(lineItem.variant.price.amount);

            productData.originalUnitPrice = costPrice + serviceCost;
            productData.requiresShipping = lineItem.variant.requiresShipping;
            productData.sku = lineItem.variant.sku;
            productData.title = lineItem.title;
            productData.weight = {
              unit: 'KILOGRAMS',
              value: lineItem.variant.weight
            };
          }

          return productData;
      });

      return Promise.all(promises);
    }


    const fetchBuyerCartById = async (id) => {
      return getCheckout(id).then(cart => {
        const cartObj = cart.response.data.node;
        return cartObj;
      })
    }

    const fetchCartQuantities = (cartId) => {
      let tempCartId = null;
      const storeKeyValue = getStorage(storeKey);
      if (!cartId && !storeKeyValue) {
        tempCartId = storeKeyValue;
        if (tempCartId) {
          getCheckout(tempCartId).then(cart => {
            const cartObj = cart.response.data?.node;
            const totalQuantity = cartObj ? _.sumBy(cartObj.lineItems.edges, line => Number(line.node.quantity)) : 0;

            setStorage(storeCartCount, totalQuantity);
          })
        } else {
          setStorage(storeCartCount, 0);
        }

      }
    }

    const removeCheckoutData = (objKey) => {
        const existingJSON = getStorage(checkoutStoreKey) || JSON.stringify({});
        const existing = JSON.parse(existingJSON);
        delete existing[objKey];
        const newSet = JSON.stringify({...existing});
        setStorage(checkoutStoreKey, newSet);
    }

    const fetchCart = async (cart, reload, init) => {
      setState({...state, cartLoading: true, cartFetched: false});

      const storedCartKey = checkoutStoreKey;
      const storedCheckoutJson = getStorage(storedCartKey) || JSON.stringify({});
      const storedCheckout = JSON.parse(storedCheckoutJson);

      let cartId = null;
      const storeKeyValue = getStorage(storeKey);
      if (init) {
        cartId = getStorage(storeKey);
        fetchCartQuantities(cartId);
        setStorage( storeKey, storeKeyValue );
      } else {
        const currentBuyerStoreKey = getStorage(storeKey);
        cartId = getStorage(currentBuyerStoreKey);
        fetchCartQuantities(cartId);
      }

      if (cartId && cartId !== 'null') {
        setState({...state, cartLoading: true, cartFetched: false});
        if (reload) {
          setState({...state, cartLoading: true, cartFetched: false});
        }
        const storedDraftOrder = storedCheckout[`draftOrder_customer`];
        const draftOrderId = storedDraftOrder?.split('/').pop() || null;

        if (storedDraftOrder) {
          getDraftOrder(draftOrderId).then(res => {
            const isCompleted = res?.response?.data?.draftOrder?.completedAt || null;
            if (isCompleted) {
              removeCheckoutCookies();
              window.location = '/';
            } else {
              removeCheckoutData('draftOrder_customer');
              draftOrderDelete(storedDraftOrder);
              getCheckout(cartId).then(cart => {
                const cartObj = cart.response.data.node;
                if (cartObj.updatedAt) {
                  storeCart(cartObj);
                }
              })
            }
          });
        } else {
          getCheckout(cartId).then(cart => {
            const cartObj = cart.response.data.node;
            if (cartObj.updatedAt) {
              storeCart(cartObj);
            }
          })
        }


      } else if ((!cart || !cart.id) && init) {
        setState({...state, cartLoading: false, cartFetched: true});
      } else if (!init && (typeof cart == 'undefined' || cart === undefined || (cart && !cart.id))) {
        setState({...state, cart: initialCartState, cartLoading: false, cartFetched: true});
      } else {
        setState({...state, cart: cart, cartLoading: false, cartFetched: true});
      }
    }

    useEffect(() => {
      fetchCart(cartctx, false, true);
      // eslint-disable-next-line
    }, []);

    const addToOrder = async (input) => {
      const cartId = getStorage(storeKey);
      return new Promise(async (res, rej) => {
        try {
            const newInput = {
              lineItems: input.lineItems,
              customAttributes: input.customAttributes,
              allowPartialAddresses: true,
              email: email,
              shippingAddress: input.shippingAddress
            }

            if (!cartId || cartId === 'null') {
              const { response, status } = await checkoutCreate(newInput);
              if (status === 200) {
                const cartObj = response.data.checkoutCreate.checkout;
                storeCart(cartObj);

                res(cartObj)
              }
            } else {
              const { response, status } = await checkoutAddItems(cartId, input.lineItems);
              if (status === 200) {
                const cartObj = response.data.checkoutLineItemsAdd.checkout;
                storeCart(cartObj);
                fetchCart(cartObj);
                res(cartObj)
              }
            }
        } catch (error) {
          rej(error);
        }
      });
    }

    const removeCustomProduct = async(id) => {
      const cartId = getStorage(storeKey);
      const cart = cartctx;
      const currentCustomItems = cart.customAttributes.filter(attr => attr.key === '_customItems');
      const newCustomAttributes = cart.customAttributes.filter(attr => attr.key !== '_customItems');
      const customItemsObj = JSON.parse(currentCustomItems[0].value);
      const newCustomProducts = customItemsObj.filter(item => item.id !== id);
      newCustomAttributes.push({
          key: '_customItems',
          value: JSON.stringify(newCustomProducts)
      });

      const input = {
        customAttributes: newCustomAttributes
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }

    const addToCartCustomProduct = async(name, sellPrice ,costPrice) => {
      const cartId = getStorage(storeKey);
      const cart = cartctx;
      const currentCustomItems = cart.customAttributes.filter(attr => attr.key === '_customItems');
      let newCustomAttributes = cart.customAttributes;

      const newCustomItems = {
        id: 'custom_1',
        name: name,
        sellPrice: sellPrice,
        costPrice: costPrice,
      }

      if (currentCustomItems.length) {
        const customItemsObj = JSON.parse(currentCustomItems[0].value);
        const currentCustomAttributes = cart.customAttributes.filter(attr => attr.key !== '_customItems');
        newCustomItems.id = `custom_${customItemsObj.length + 1}`;
        customItemsObj.push(newCustomItems);
        currentCustomAttributes.push({
          key: '_customItems',
          value: JSON.stringify(customItemsObj)
        });
        newCustomAttributes = currentCustomAttributes;
      } else {
        newCustomAttributes.push({
          key: '_customItems',
          value: JSON.stringify([newCustomItems])
        })
      }

      const input = {
        customAttributes: newCustomAttributes
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });

      // checkoutAttributesUpdate(cartId, customAttributes)
    }

    const addPONumber = async(number, url) => {
      setState({...state, cartLoading: true, cartFetched: false});
      const cartId = getStorage(storeKey);
      const cart = cartctx;
      const currentPONumber = cart.customAttributes.filter(attr => attr.key === '_poNumber');
      let newCustomAttributes = cart.customAttributes;

      if (currentPONumber.length) {
        const currentCustomAttributes = cart.customAttributes.filter(attr => attr.key !== '_poNumber' || attr.key !== '_poUrl');
        currentCustomAttributes.push({
          key: '_poNumber',
          value: number
        });
        currentCustomAttributes.push({
          key: '_poUrl',
          value: url
        });
        newCustomAttributes = currentCustomAttributes;
      } else {
        newCustomAttributes.push({
          key: '_poNumber',
          value: number
        });
        newCustomAttributes.push({
          key: '_poUrl',
          value: url
        });
      }

      const input = {
        customAttributes: newCustomAttributes
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });

      // checkoutAttributesUpdate(cartId, customAttributes)
    }

    const removePoNumber = async() => {
      setState({...state, cartLoading: true, cartFetched: false});
      const cartId = getStorage(storeKey);
      const cart = cartctx;
      let newCustomAttributes = cart.customAttributes;

      let currentCustomAttributes = cart.customAttributes.filter(attr => attr.key !== '_poNumber');
      currentCustomAttributes = cart.customAttributes.filter(attr => attr.key !== '_poUrl');
      newCustomAttributes = currentCustomAttributes;

      const input = {
        customAttributes: newCustomAttributes
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });

      // checkoutAttributesUpdate(cartId, customAttributes)
    }

    const addToCart = async (lineItems, productId, buyerType, markup, location, calculatePrice, modifiers) => {

      // const findExistingItem = cartctx?.lineItems?.find(item => item.variant.id === lineItems?.variantId);

      const cartId = getStorage(storeKey);
      const lineItemAttribute = [];

      setState({...state, addingToCart: productId});

      const buyerAttributes = {
        key: 'Buyer Type',
        value: buyerType,
      };

      if (location) {
        const locationIdObj = JSON.parse(location.value);

        lineItemAttribute.push({
          key: '_locationName',
          value: location.label
        })

        // lineItemAttribute.push({
        //   key: '_locationNetsuiteName',
        //   value: locationIdObj.netsuiteName
        // })

        lineItemAttribute.push({
          key: '_locationId',
          value: locationIdObj.locationId
        })
        lineItemAttribute.push({
          key: '_inventoryItemId',
          value: locationIdObj.inventoryItemId
        })
      }

        lineItemAttribute.push({
          key: 'calculatePrice',
          value: String(calculatePrice ?? 0)
        })

        if(modifiers && modifiers.length > 0) {
          for (const modifier of modifiers) {
            lineItemAttribute.push({
              key: modifier.key,
              value: String(modifier.value),
            })
          }
        }

      if (!Array.isArray(lineItems)) {
        lineItems.customAttributes = lineItemAttribute;
      }

      const input = {
        lineItems,
        customAttributes: buyerAttributes,
        allowPartialAddresses: true,
        email: email,
        // shippingAddress: {
        //   city: 'Sydney',
        //   province: 'New South Wales',
        //   country: 'Australia',
        // }
      }

      if (!cartId || cartId === 'null') {
        checkoutCreate(input).then(async cart => {
          const cartObj = cart.response.data.checkoutCreate.checkout;
          if (cartObj.updatedAt) {

            addNotification('Item added successfully');
            const newCartObj = await storeCart(cartObj);
            setState({...state, cart: newCartObj.cart, addingToCart: null});
          }
        });
      } else {
        checkoutAddItems(cartId, lineItems).then(cart => {
          const cartObj = cart.response.data.checkoutLineItemsAdd.checkout;
          if (cartObj.updatedAt) {

            addNotification('Item added successfully');
            storeCart(cartObj);
            fetchCart(cartObj);
            setState({...state, addingToCart: null});

            if (!cartObj.email) {
              checkoutUpdateEmail(cartId, email);
              storeCart(cartObj);
              fetchCart(cartObj);
              setState({...state, addingToCart: null});
            }
          }
        });
      }
    };

    const removeItemFromCart = (lineId) => {
      // setState({...state, cartLoading: true, cartFetched: false});

      const cartId = id;
      const lineItemIds = [lineId];

      // return client.checkout.removeLineItems(cartId, [lineId]).then(res => {
      //   storeCart(res);
      // })
      return checkoutRemoveItems(cartId, lineItemIds).then(cart => {
        const cartObj = cart.response.data.checkoutLineItemsRemove.checkout;
        if (cartObj.updatedAt) {
          // addNotification('Item removed successfully');
          storeCart(cartObj);
          fetchCart(cartObj);
        }
      });
    };

    const updateCartItemQuantity = (lineItem, qty) => {
      setState({...state, cartLoading: true, cartFetched: false});

      const cartId = stateCartId;
      const lineItems = [{
        id: lineItem.id,
        quantity: parseInt(qty, 10),
        variantId: lineItem.variant.id
      }];
      // return client.checkout.updateLineItems(cartId, lineItem).then(res => {
      //   storeCart(res);
      // })
      const variant = lineItem.variant;
      const sellPriceObj = lineItem.customAttributes.length && lineItem.customAttributes.find(attr => attr.key === '_sellPrice');
      // services
      const powderCoating = lineItem.customAttributes.find(attr => attr.key === 'powderCoating');
      const powderCoatingCost = powderCoating ? parseFloat(powderCoating.value) : 0;
      const assemblyFee = lineItem.customAttributes.find(attr => attr.key === 'assemblyService');
      const assemblyCost = assemblyFee ? parseFloat(assemblyFee.value) : 0;
      const cutdownFee = lineItem.customAttributes.find(attr => attr.key === 'cutdownService');
      const substrateFee = lineItem.customAttributes.find(attr => attr.key === 'substrate');
      let cutdownValue = [];
      if(cutdownFee) {
      cutdownValue = cutdownFee.value.split(':');
      }
      const cutdownCost = cutdownValue && cutdownValue.length ? parseFloat(cutdownValue[0]) : 0;
      let substrateValue = [];
      if(substrateFee) {
      substrateValue = substrateFee.value.split(':');
      }
      const substrateCost = substrateValue && substrateValue.length ? parseFloat(substrateValue[0]) : 0;
      const services = powderCoatingCost + assemblyCost + cutdownCost + substrateCost;

      const sellPrice = sellPriceObj?.value || (((parseFloat(variant.compareAtPrice?.amount) / 1.1) + services) * lineItem.quantity) || ((parseFloat(variant.price.amount) + services) * lineItem.quantity);

      const newSellPrice = (sellPrice / lineItem.quantity) * qty;

      return checkoutUpdateItems(cartId, lineItems).then(cart => {
        const cartObj = cart.response.data.checkoutLineItemsUpdate.checkout;
        if (cartObj.updatedAt) {
          updateItemSellPrice(newSellPrice.toFixed(2), lineItem, parseInt(qty, 10) > 49 ? lineItem?.variant?.priceLevel50plus : lineItem?.variant?.priceLevel);

          // fetchCart(cartObj);
          // addNotification('Item updated successfully');

          if (!cartObj.email) {
            checkoutUpdateEmail(cartId, email);
            storeCart(cartObj);
            fetchCart(cartObj);
          }
        }
      });
    }

    const addCoupons = async code => {
      setState({...state, cartLoading: true, cartFetched: false});
      // return client.checkout.addDiscount(cartId, code).then(res => {
      //   storeCart(res);
      // })
      return new Promise(async (res, rej) => {
        try {
          const cartId = stateCartId;
          const { response, status } = await checkoutDiscountAdd(cartId, code);
          const cartObj = get(response, 'data.checkoutDiscountCodeApplyV2.checkout');
          const couponObj = get(response, 'data.checkoutDiscountCodeApplyV2.checkout.discountApplications.edges');
          if (status === 200 && couponObj.length) {
            const coupons = calculateDiscounts(cartObj);
            storeCart(cartObj);
            res(coupons);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }

    const removeCoupons = () => {
      setState({...state, cartLoading: true, cartFetched: false});

      // const cartId = state.cart.id;

      // return client.checkout.removeDiscount(cartId).then(res => {
      //   storeCart(res);
      // })

      return new Promise(async (res, rej) => {
        try {
          const cartId = stateCartId;
          const { response, status } = await checkoutDiscountRemove(cartId);
          const cartObj = get(response, 'data.checkoutDiscountCodeRemove.checkout');
          const coupons = get(response, 'data.checkoutDiscountCodeRemove.checkout.discountApplications.edges');
          if (status === 200) {
            storeCart(cartObj);
            res(coupons);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
    });
    }

    const updateEmail = (email) => {
      setState({...state, cartLoading: true, cartFetched: false});

      const cartId = stateCartId;

      return client.checkout.updateEmail(cartId, email).then(res => {
        // console.log(res);
        storeCart(res);
      });
    }

    const addConsignments = (shippingAddress) => {
      // setState({...state, cartLoading: true, cartFetched: false});

      const cartId = stateCartId;

      return checkoutShippingAddressUpdate(cartId, shippingAddress).then(cart => {
        const cartObj = cart.response.data.checkoutShippingAddressUpdateV2.checkout;
        if (cartObj.updatedAt) {
          // console.log(cartObj);
          return storeCart(cartObj);
        }
      })
    }

    const updateConsignments = (shippingAddress) => {
      return addConsignments(shippingAddress);
    }

    const changeShippingMethod = async (shippingRateHandle) => {
      // setState({...state, cartLoading: true, cartFetched: false});
      let shippingMethod = shippingRateHandle;
      if (shippingRateHandle.value) {
        shippingMethod = shippingRateHandle.value;
      }

      const cartId = stateCartId;

      return checkoutShippingLineUpdate(cartId, shippingMethod).then(async cart => {

        const cartObj = cart.response.data.checkoutShippingLineUpdate.checkout;
        if (cartObj.updatedAt) {
          storeCart(cartObj);
          // const filteredCustomAttr = cartObj.customAttributes.filter(attr => attr.key !== '_customShippingAmount');

          // const input = {
          //     customAttributes: filteredCustomAttr,
          //     note: cartctx.note || ''
          // }
          // return checkoutAttributesUpdate(cartId, input).then(res => {
          //   const newCartObj = res.response.data.checkoutAttributesUpdateV2.checkout;
          //   return storeCart(newCartObj);
          // })
        }
      })
    }

    const initCheckout = async () => {
      fetchCart();
    };

    const saveQuote = useCallback(async(input) => {
      setState({...state, cartLoading: true, cartFetched: false});
      const { response, status } = await draftOrderCreate(input);
      const draftObj = get(response, 'data.draftOrderCreate.draftOrder');

      if (status === 200 && draftObj) {
        return draftObj;
      }
    }, [state]);

    const saveOrder = async(id, paymentPending) => {
      // setState({...state, cartLoading: true, cartFetched: false});
      const { response, status } = await draftOrderComplete(id, paymentPending);
      const draftObj = get(response, 'data.draftOrderComplete.draftOrder');

      if (status === 200 && draftObj) {
        return draftObj;
      }
    }

    const updateQuote = async(id, input) => {
      // setState({...state, cartLoading: true, cartFetched: false});
      const { response, status } = await draftOrderUpdate(id, input);
      const draftObj = get(response, 'data.draftOrderUpdate.draftOrder');

      if (status === 200 && draftObj) {
        return draftObj;
      }
    }

    const getQuotes = async(companyId, perPage, query, cursor) => {
      const { response } = await getDraftOrders(companyId, perPage, query, cursor);
      const draftOrders = [];
      const draftOrderNodes = get(response, 'data.company.draftOrders.edges')?.map(draftOrder => { return draftOrder.node });
      draftOrders.nodes = draftOrderNodes;
      draftOrders.pageInfo = get(response, 'data.company.draftOrders.pageInfo');

      if (draftOrders.nodes && draftOrders.nodes.length) {
        return draftOrders;
      }
    }

    const fetchQuote = useCallback(async (cart, isProceedToSaveQuote) => {
      const storedCartKey = checkoutStoreKey;
      const storedCheckoutJson = getStorage(storedCartKey) || JSON.stringify({});
      const storedCheckout = JSON.parse(storedCheckoutJson);

      if (cart.id === storedCheckout.checkoutId) {
        let draftObj = false;
        let discount = {};
        const buyerType = cart.buyerType;

        const isResellerCart = buyerType === 'reseller';

        const customAttributes = cart.customAttributes;

        const paymentTerm = customAttributes.find(attr => attr.key === '_paymentTerm');

        // if (cart.discountApplications?.edges.length) {
            // const discountNode = cart.discountApplications?.edges[0].node;
            // if (discountNode.value.percentage) {
            //   discount.valueType = 'PERCENTAGE';
            //   discount.value = parseFloat(discountNode.value.percentage);
            //   discount.title = 'coupon';
            // } else if (discountNode.value.amount) {
              discount.valueType = 'FIXED_AMOUNT';
              // discount.value = parseFloat(discountNode.value.amount);
              discount.value = cart.totalDiscountAmount || 0;
              discount.title = 'coupon';
            // }
        // }

        // console.log('draftOrderDiscount', discount);

        const input = {
            email: cart.email,
            appliedDiscount: discount,
            customAttributes: customAttributes
        }

        if (storedCheckout.billing) {
            input.billingAddress = {
                address1: storedCheckout.billing.address1,
                address2: storedCheckout.billing.address2,
                city: storedCheckout.billing.city,
                company: storedCheckout.billing.company,
                country: storedCheckout.billing.country,
                firstName: storedCheckout.billing.firstName,
                lastName: storedCheckout.billing.lastName,
                phone: storedCheckout.billing.phone,
                province: storedCheckout.billing.province,
                zip: storedCheckout.billing.zip,
            }
        }

        if (storedCheckout.shipping) {
            input.shippingAddress = {
                address1: storedCheckout.shipping.address1,
                address2: storedCheckout.shipping.address2,
                city: storedCheckout.shipping.city,
                company: storedCheckout.shipping.company,
                country: storedCheckout.shipping.country,
                firstName: storedCheckout.shipping.firstName,
                lastName: storedCheckout.shipping.lastName,
                phone: storedCheckout.shipping.phone,
                province: storedCheckout.shipping.province,
                zip: storedCheckout.shipping.zip,
            }
        }

        if (isResellerCart && customerId && companyContactId && companyId && companyLocationId) {
          input.purchasingEntity = {
            purchasingCompany: {
              companyContactId: companyContactId,
              companyId: companyId,
              companyLocationId: companyLocationId,
            }
          }
        }

        if (paymentTerm) {
          const term = JSON.parse(paymentTerm.value);
          if (term.title.includes('NET')) {
            input.paymentTerms = {
              paymentSchedules: {
                dueAt: term.dueAt,
                issuedAt: term.issuedAt,
              },
              paymentTermsTemplateId: term.id
            }
          }
        }

        input.lineItems = await setupCheckoutProducts(cart, isResellerCart, isProceedToSaveQuote);
        input.note = cart.note;
        input.visibleToCustomer = true;

        const shippingLineAttrs = cart.customAttributes.filter(attr => attr.key.includes('_shippingLine'));
        const shippingLineTotals = sumBy(shippingLineAttrs, line => {
          const val = line.value !== 'null' && line.value !== 'undefined' ? JSON.parse(line.value) : null;
          const price = val ? parseFloat(val.price.amount) : 0;
          return price;
        }) || 0;

        if (shippingLineAttrs) {
          input.shippingLine = {
            price: shippingLineTotals,
            title: 'Shipping',
            // shippingRateHandle: cart.shippingLineMachship.handle
          }
        }
        if (cart.poNumber) {
          input.poNumber = cart.poNumber;
        }

        // console.log(input);


        if (input.lineItems && input.lineItems.length && input.shippingLine && input.billingAddress && input.shippingAddress && input.email) {
          draftObj = await saveQuote(input);
        } else {
          console.log('Incomplete Data');
        }

        // console.log('draftObj', draftObj);

        return draftObj;
      }

    }, [saveQuote, companyContactId, companyId, companyLocationId, customerId]);

    const storeCart = useCallback(async cartObj => {
      setStorage(storeKey, cartObj.id);

      // Mapped Line Items
      const lineItems = cartObj.lineItems.edges.map(line => {
        const userLvl = getStorage('__jammUsrLvl') || cleansePriceLevel(priceLevelCtx);
        const newLine = line.node;
        const variant = newLine.variant;
        const pricesMeta = variant.priceLevels ? variant.priceLevels : null;
        const pricesMetaObj = pricesMeta ? parsedMetaData(pricesMeta) : null;
        const priceLevel = pricesMetaObj ? pricesMetaObj[userLvl] : null;
        // const priceLevel50plus = pricesMetaObj ? pricesMetaObj[`${userLvl}_50plus`] : null;
        const priceLevel50plus = priceLevel;
        variant.priceLevel = priceLevel || null;
        variant.priceLevel50plus = priceLevel50plus || null;

        // services
        const powderCoating = newLine.customAttributes.find(attr => attr.key === 'powderCoating');
        const powderCoatingCost = powderCoating ? parseFloat(powderCoating.value) : 0;
        const assemblyFee = newLine.customAttributes.find(attr => attr.key === 'assemblyService');
        const assemblyCost = assemblyFee ? parseFloat(assemblyFee.value) : 0;
        const cutdownFee = newLine.customAttributes.find(attr => attr.key === 'cutdownService');
        const substrateFee = newLine.customAttributes.find(attr => attr.key === 'substrate');
        let cutdownValue = [];
        if(cutdownFee) {
          cutdownValue = cutdownFee.value.split(':');
        }
        const cutdownCost = cutdownValue && cutdownValue.length ? parseFloat(cutdownValue[0]) : 0;
        let substrateValue = [];
        if(substrateFee) {
          substrateValue = substrateFee.value.split(':');
        }
        const substrateCost = substrateValue && substrateValue.length ? parseFloat(substrateValue[0]) : 0;

        const services = powderCoatingCost + assemblyCost + substrateCost + cutdownCost;
        ;
        // Cost Price
        const costPrice = line.quantity > 49 && priceLevel50plus ? priceLevel50plus : priceLevel ? priceLevel : parseFloat(variant?.price.amount);

        // Sell Price
        const customSellPriceAttr = newLine.customAttributes.find(
          (attr) => attr.key === '_sellPrice'
        );
        const originalPrice = parseFloat(newLine.variant?.price.amount);
        const compareAtPriceExGst = parseFloat(newLine.variant.compareAtPrice.amount) / 1.1;
        let sellPrice =  parseFloat(customSellPriceAttr?.value) ||
        (compareAtPriceExGst + services) * newLine.quantity ||
        (originalPrice + services) * newLine.quantity ||
        0;

        // Set new cost and sell prices with services
        variant.costPrice = costPrice + services;
        variant.sellPrice = sellPrice;

        return newLine;
      });

      // Shipping Method
      const shippingLineAttrs = cartObj.customAttributes.filter(attr => attr.key.includes('_shippingLine'));
      shippingLineAttrs.map(line => {
        const val = line.value !== 'null' && line.value !== 'undefined' ? JSON.parse(line.value) : null;
        cartObj[line.key] = val ? {
          label: val.title,
          value: line.value
        } : '';
        return true;
      })
      const shippingLine = shippingLineAttrs[0] ? JSON.parse(shippingLineAttrs[0].value) : null;
      const shippingMethod = shippingLine ? shippingLine.handle.includes('Collect') ? 'collect' : 'delivery' : null;

      // Cubic Calculations
      const cubicCalc = calculateProductsPackage(cartObj.lineItems?.edges);

      // PO Number
      const poNumber = cartObj.customAttributes.find(attr => attr.key === '_poNumber') || null;
      const poUrl = cartObj.customAttributes.find(attr => attr.key === '_poUrl') || null;

      // Quantity
      const totalQuantity = cartObj ? _.sumBy(lineItems, line => Number(line.quantity)) : 0;

      // Additional Service Items
      const customItemsAttr = cartObj.customAttributes.filter(attr => attr.key === '_customItems');
      const customItemsObj = customItemsAttr.length ? JSON.parse(customItemsAttr[0].value) : false;
      const customItemsSellTotal = sumBy(customItemsObj, line => parseFloat(line.sellPrice)) || 0;
      const customItemsCostTotal = sumBy(customItemsObj, line => parseFloat(line.costPrice)) || 0;

      // Shipping Totals
      const shippingLineTotals = sumBy(shippingLineAttrs, line => {
        const val = line.value !== 'null' && line.value !== 'undefined' ? JSON.parse(line.value) : null;
        const price = val ? parseFloat(val.price.amount) : 0;
        return price;
      }) || 0;
      const shippingCostGst = (shippingLineTotals * .10) || 0;
      const shippingCost = shippingLineTotals || 0
      const customShippingAmount = cartObj.customAttributes.find(attr => attr.key === '_customShippingAmount') || null;
      const shippingSellGst = (parseFloat(customShippingAmount?.value) * .10) || 0;
      const shippingSell = (parseFloat(customShippingAmount?.value)) || 0;

      // Line Items Totals
      const lineItemsCostTotal = cartObj ? _.sumBy(lineItems, line => line.variant.costPrice * line.quantity) : 0;
      const lineItemsSellTotal = cartObj ? _.sumBy(lineItems, line => line.variant.sellPrice) : 0;

      // Coupons
      const withCoupons = !!cartObj.discountApplications.edges.length;
      const coupons = withCoupons && lineItemsSellTotal ? calculateDiscounts(cartObj, lineItemsSellTotal) : false;
      // const couponDiscountAmount = (coupons && coupons[0].discounted_amount) || 0;

      // Subtotals
      const subtotalCost = lineItemsCostTotal + shippingCost;
      const subtotalSell = lineItemsSellTotal;
      const subtotalCostWithService = lineItemsCostTotal + customItemsCostTotal + shippingCost;
      const subtotalSellWithService = lineItemsSellTotal + customItemsSellTotal + shippingSell;


      // GST Calculations
      const gstCost = ((subtotalCost) * .10);
      const gstSell = ((subtotalSell) * .10) + shippingSellGst;
      const gstCostWithService = ((subtotalCostWithService) * .10);
      const gstSellWithService = ((subtotalSellWithService) * .10);

      // Grand Totals
      const grandTotalCost = subtotalCost + gstCost;
      const grandTotalSell = subtotalSell + shippingSell + gstSell;
      const grandTotalCostWithService = subtotalCostWithService + gstCostWithService;
      const grandTotalSellWithService = subtotalSellWithService + gstSellWithService;

      // Markup
      const markup = `${(((subtotalSellWithService - subtotalCostWithService) / subtotalSellWithService) * 100).toFixed(0)}%`;

      // Gross Profit Margins
      const grossProfitMarginPercentage = `${(((subtotalSellWithService - subtotalCostWithService) / subtotalSellWithService) * 100).toFixed(0)}%`;
      const grossProfitMarginAmount = subtotalSellWithService - subtotalCostWithService;

      cartObj.buyerType = 'reseller';
      cartObj.cubicSize = cubicCalc?.cubicSize || 0;
      cartObj.weight = _.sumBy(cartObj.lineItems.edges, line => Number(line.node.variant.weight) * line.node.quantity) || 0;
      cartObj.lineItems = cartObj.lineItems.edges?.map(line => line.node);
      cartObj.currency = {code: cartObj.currencyCode};
      cartObj.coupons = coupons;
      cartObj.numberItems = totalQuantity || 0;
      cartObj.poNumber = poNumber?.value || null;
      cartObj.poUrl = poUrl?.value || null;
      cartObj.shippingMethod = shippingMethod;
      // cartObj.shippingLineMachship = shippingLine;

      cartObj.shipping_cost = shippingCost;
      cartObj.shipping_sell = shippingSell;
      cartObj.shipping_cost_gst = shippingCostGst;
      cartObj.shipping_sell_gst = shippingSellGst;
      cartObj.subtotal_cost = subtotalCost;
      cartObj.subtotal_sell = subtotalSell;
      cartObj.subtotal_cost_with_service = subtotalCostWithService;
      cartObj.subtotal_sell_with_service = subtotalSellWithService;
      cartObj.gst_cost = gstCost;
      cartObj.gst_sell = gstSell;
      cartObj.gst_cost_with_service = gstCostWithService;
      cartObj.gst_sell_with_service = gstSellWithService;
      cartObj.grand_total_cost = grandTotalCost;
      cartObj.grand_total_sell = grandTotalSell;
      cartObj.grand_total_cost_with_service = grandTotalCostWithService;
      cartObj.grand_total_sell_with_service = grandTotalSellWithService;
      cartObj.markup = markup;
      cartObj.gross_profit_margin_percentage = grossProfitMarginPercentage;
      cartObj.gross_profit_margin_amount = grossProfitMarginAmount;

      delete cartObj.lineItemsSubtotalPrice;
      delete cartObj.paymentDue;
      delete cartObj.totalPrice;
      delete cartObj.totalTax;
      delete cartObj.taxExempt;
      delete cartObj.taxesIncluded;
      delete cartObj.totalDuties;

      setStorage(storeCartCount, totalQuantity);
      // const quote = state.quote ? state.quote : await fetchQuote(isResellerCart ? resellerCart : cartObj) ;
      // const cartFetchedState = quote?.completedAt?.length ? false : true;

      // console.log(cartObj);

      const newState = {...state, cart: cartObj, cartLoading: false, cartFetched: true};
      setState(newState);

      return newState;

    }, [state, calculateDiscounts, priceLevelCtx]);

    const getOrderHistory = async(perPage, query, cursor) => {
      const { response } = await getOrders(perPage, query, cursor);
      const orders = [];
      const orderNodes = get(response, 'data.company.orders.edges')?.map(orders => { return orders.node }, []);
      orders.nodes = orderNodes;
      orders.pageInfo = get(response, 'data.company.orders.pageInfo');

      if (orders.nodes && orders.nodes.length) {
        return orders;
      }
    }

    const getCustomerOrderHistory = async(perPage, query, cursor) => {
      const { response } = await getCustomerOrders(perPage, query, cursor);
      const orders = [];
      const orderNodes = get(response, 'data.orders.edges').map(orders => { return orders.node }, []);
      orders.nodes = orderNodes;
      orders.pageInfo = get(response, 'data.orders.pageInfo');

      if (orders.nodes && orders.nodes.length) {
        return orders;
      }
    }

    const updateCartItemMarkup = (percentage, item) => {
      setState({...state, cartLoading: true, cartFetched: false});
      const cartId = stateCartId;
      const newCustomAttributes = item.customAttributes.filter(attr => attr.key !== '_markup');
      newCustomAttributes.push({
        key: '_markup',
        value: percentage
      })
      const lineItems = [{
        id: item.id,
        variantId: item.variant.id,
        customAttributes: newCustomAttributes
      }];
      checkoutUpdateItems(cartId, lineItems).then(cart => {
        const cartObj = cart.response.data.checkoutLineItemsUpdate.checkout;
        if (cartObj.updatedAt) {
          storeCart(cartObj);
          fetchCart(cartObj);
          // addNotification('Item updated successfully');
        }
      });
    }

    const updateItemSellPrice = (amount, item, calcPrice) => {
      setState({...state, cartLoading: true, cartFetched: false});
      const cartId = stateCartId;
      const newCustomAttributes = item.customAttributes.filter(attr => attr.key !== '_sellPrice' && attr.key !== 'calculatePrice');
      newCustomAttributes.push({
        key: '_sellPrice',
        value: amount
      })
      newCustomAttributes.push({
        key: 'calculatePrice',
        value: String(calcPrice ?? 0)
      })
      const lineItems = [{
        id: item.id,
        variantId: item.variant.id,
        customAttributes: newCustomAttributes
      }];
      checkoutUpdateItems(cartId, lineItems).then(cart => {
        const cartObj = cart.response.data.checkoutLineItemsUpdate.checkout;
        if (cartObj.updatedAt) {
          storeCart(cartObj);
          fetchCart(cartObj);
          // addNotification('Item updated successfully');
        }
      });
    }

    const getCollectionLocations = async () => {
      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await getPickupLocations();
          const collectionLocations = get(response, 'data.locations.edges') || false;
          if (status === 200) {
            res(collectionLocations);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }

    const getCountryStates = async (countryIso) => {
      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await getDeliveryLocations(countryIso);
          const deliveryLocations = get(response, 'data.deliveryProfiles.edges[0].node.profileLocationGroups[0].countriesInAnyZone') || false;
          if (status === 200) {
              let countryStates = deliveryLocations.map(location => {
                const states = location.country.provinces.map(province => {
                    return {
                        state: province.name,
                        code: province.code
                    }
                });

                return {
                    node: {
                        country_iso2: location.country.code.countryCode,
                        country: location.country.name,
                        states: states
                    }
                }
            });

            if (countryIso) {
              countryStates = countryStates.filter(countryState => countryState.node.country_iso2 === countryIso);
            }

            res(countryStates);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }

    const setCheckoutOption = (option) => {
      setState({...state, checkoutOption: option});
    };

    const setShippingAmount = (amount) => {

      const cartId = getStorage(storeKey);
      const newCustomAttributes = cartctx.customAttributes;
      const filteredCustomAttr = newCustomAttributes.filter(attr => attr.key !== '_customShippingAmount');
      filteredCustomAttr.push({
        key: '_customShippingAmount',
        value: amount
      });
      const input = {
          customAttributes: filteredCustomAttr,
          note: cartctx.note || ''
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    };

    const setShippingLine = (shippingLine = null, location) => {

      const cartId = getStorage(storeKey);
      const newCustomAttributes = cartctx.customAttributes;
      const filteredCustomAttr = newCustomAttributes.filter(attr => attr.key !== (`_shippingLine_${location}`) && attr.key !== '_customShippingAmount');

      filteredCustomAttr.push({
        key: `_shippingLine_${location}`,
        value: shippingLine || 'null'
      });

      const shippingLineAttrs = filteredCustomAttr.filter(attr => attr.key.startsWith('_shippingLine_'))
      const shippingExGST =  sumBy(shippingLineAttrs, line => {
        const val = line.value !== 'null' && line.value !== 'undefined' ? JSON.parse(line.value) : null;
        const price = val ? parseFloat(val.price.amount) : 0;
        return price;
      }) || 0;

      if (shippingExGST) {
        filteredCustomAttr.push({
          key: '_customShippingAmount',
          value: shippingExGST.toFixed(2)
        });
      }

      const input = {
          customAttributes: filteredCustomAttr,
          note: cartctx.note || ''
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    };

    const removeShippingLine = (location) => {
      const cartId = getStorage(storeKey);
      const newCustomAttributes = cartctx.customAttributes;

      const shippingLineAttrs = newCustomAttributes.filter(attr => attr.key.includes('_shippingLine'));
      const shippingLineTotals = sumBy(shippingLineAttrs, line => {
        const val = line.value !== 'null' && line.value !== 'undefined' ? JSON.parse(line.value) : null;
        const price = val ? parseFloat(val.price.amount) : 0;
        return price;
      }) || 0;
      const shippingExGST = shippingLineAttrs ? shippingLineTotals : null;

      const filteredCustomAttr = newCustomAttributes.filter(attr => attr.key !== (`_shippingLine_${location}`) && attr.key !== '_customShippingAmount');

      if (shippingExGST) {
        filteredCustomAttr.push({
          key: '_customShippingAmount',
          value: shippingExGST.toFixed(2)
        });
      }

      const input = {
          customAttributes: filteredCustomAttr,
          note: cartctx.note || ''
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    };

    const getPaymentTerms = () => {
      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await getPaymentTermsTemplates();
          const paymentTerms = get(response, 'data.paymentTermsTemplates');
          if (status === 200) {
            const filteredTerms = paymentTerms.filter(term => term.paymentTermsType === 'NET' || term.paymentTermsType === 'RECEIPT');
            res(filteredTerms);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }

    const setPaymentTerm = (term) => {

      const cartId = getStorage(storeKey);
      const newCustomAttributes = cartctx.customAttributes;
      if(!cartctx.customAttributes) return;

      const filteredCustomAttr = newCustomAttributes.filter(attr => attr.key !== '_paymentTerm');

      filteredCustomAttr.push({
        key: '_paymentTerm',
        value: term
      });

      try {
        const termObject = JSON.parse(term);

        if(filteredCustomAttr.find(attr => attr.key === '_companyTerm')) {
          filteredCustomAttr.map(attr => {
            if (attr.key === '_companyTerm') {
              attr.value = termObject?.companyTerm;
            }
            return attr;
          });
        } else {
          filteredCustomAttr.push({
            key: '_companyTerm',
            value: termObject?.companyTerm,
          });
        }
      } catch {
        // do nothing
      }

      const input = {
          customAttributes: filteredCustomAttr,
          note: cartctx.note || ''
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await checkoutAttributesUpdate(cartId, input);
          const cartObj = get(response, 'data.checkoutAttributesUpdateV2.checkout');
          if (status === 200) {
            storeCart(cartObj);
            res(cartObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    };

    const updateItemLocation = (item, location, source, object) => {
      // console.log('item', item);
      // console.log('location', location);
      // console.log('source', source);

      const itemId = item.id;
      const locationName = `${location.label} Warehouse`
      const locationObj = JSON.parse(location?.value);
      const inventoryItemId = locationObj.inventoryItemId;
      const locationId = locationObj.locationId;

      let containerObj = cartctx;
      let id = getStorage(storeKey);
      let updateFunction = checkoutUpdateItems;
      let result = 'data.checkoutLineItemsUpdate.checkout';
      let lineItem = containerObj.lineItems.find(line => line.id === itemId);
      let newLineItems = containerObj.lineItems.filter(line => line.id !== itemId);

      switch(source) {
        case 'saved-quote':
          updateFunction =  draftOrderUpdate;
          result = 'data.draftOrderUpdate.draftOrder';
          containerObj = object;
          id = containerObj.id;
          lineItem = containerObj.lineItems.edges.find(line => line.node.id === itemId);
          lineItem = lineItem.node;
          newLineItems = containerObj.lineItems.edges.filter(line => line.node.id !== itemId);
          newLineItems = newLineItems.map(line => line.node);
          break;
        // case 'quick-quote':
        //   result = 'data.draftOrderCalculate.draftOrder';
        //   containerObj = object;
        //   id = containerObj.id;
        //   lineItem = containerObj.lineItems.edges.find(line => line.node.id === itemId);
        //   lineItem = lineItem.node;
        //   newLineItems = containerObj.lineItems.edges.filter(line => line.node.id !== itemId);
        //   newLineItems = newLineItems.map(line => line.node);
        //   break;
        default:
          updateFunction =  checkoutUpdateItems;
      }

      const newCustomAttributes = lineItem.customAttributes;
      if(!lineItem.customAttributes) return;

      const filteredCustomAttr = newCustomAttributes.filter(attr => attr.key !== '_locationName' && attr.key !== '_locationId' && attr.key !== '_inventoryItemId');

      if (locationName) {
        filteredCustomAttr.push({
          key: '_locationName',
          value: !locationName.includes(' Warehouse') ? `${locationName}` : locationName.split(' Warehouse')[0]
        })
      }

      if (locationId) {
        filteredCustomAttr.push({
          key: '_locationId',
          value: locationId
        })
      }

      if (inventoryItemId) {
        filteredCustomAttr.push({
          key: '_inventoryItemId',
          value: inventoryItemId
        })
      }

      lineItem.customAttributes = filteredCustomAttr;
      newLineItems.push(lineItem);

      const lineItemsInput = newLineItems.map(line => {
        return {
          customAttributes: line.customAttributes,
          id: line.id,
          quantity: line.quantity,
          variantId: line.variant.id,
        }
      });

      let newInput = lineItemsInput;

      switch(source) {
        case 'saved-quote':
          newInput = {
            lineItems: newLineItems.map(line => {
              return {
                customAttributes: line.customAttributes,
                quantity: line.quantity,
                variantId: line.variant.id,
              }
            })
          }
          break;
        // case 'quick-quote':
        //   newInput = {
        //     lineItems: newLineItems.map(line => {
        //       return {
        //         customAttributes: line.customAttributes,
        //         quantity: line.quantity,
        //         variantId: line.variant.id,
        //       }
        //     })
        //   }
        //   break;
        default:
      }

      return new Promise(async (res, rej) => {
        try {
          const { response, status } = await updateFunction(id, newInput);
          const newObj = get(response, result);
          if (status === 200) {
            if (source === 'cart') { storeCart(newObj) };
            res(newObj);
          } else {
            rej(response);
          }
        } catch (error) {
          setState({ ...state, cartLoading: false, cartError: error });
          rej(error);
        }
      });
    }


    const loadQuote = false;
    const addAllToCart = false;
    const clearCart = false;
    const addGiftCertificates = false;
    const changeSelectedStore = false;
    const loadingStock = false;
    const updateStockAvailability = false;
    // const initCheckout = false;

    return (
        <CartContext.Provider value={{
            state,
            fetchCart,
            fetchQuote,
            saveQuote,
            updateQuote,
            loadQuote,
            getQuotes,
            getOrderHistory,
            getCustomerOrderHistory,
            addToOrder,
            addToCart,
            addAllToCart,
            addCoupons,
            removeCoupons,
            removeItemFromCart,
            updateCartItemQuantity,
            clearCart,
            notifications,
            addNotification,
            removeNotification,
            updateEmail,
            addConsignments,
            addGiftCertificates,
            updateConsignments,
            changeShippingMethod,
            changeSelectedStore,
            loadingStock,
            updateStockAvailability,
            initCheckout,
            fetchBuyerCartById,
            updateCartItemMarkup,
            updateItemSellPrice,
            getCollectionLocations,
            getCountryStates,
            setCheckoutOption,
            addToCartCustomProduct,
            removeCustomProduct,
            setShippingAmount,
            addPONumber,
            removePoNumber,
            getPaymentTerms,
            setPaymentTerm,
            saveOrder,
            updateItemLocation,
            setShippingLine,
            removeShippingLine
        }}>{children}</CartContext.Provider>
    );
};

export default CartContext;