import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthContext from './AuthProvider';
import { useStaticQuery, graphql } from 'gatsby';
// import moment from 'moment/moment';
import { setMetafield } from '../helpers/shopify';
import { getStorage, setStorage } from '../helpers/general';
import moment from 'moment';
// import { isEqual } from 'lodash';

const NotificationContext = createContext();

const initialState = {
    notificationList: null,
    notificationCount: 0,
};

initialState.notificationList = [];
initialState.notificationCount = initialState.notificationList?.map(note => !note.isRead).length || 0;

export const NotificationProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const customerData = auth && auth.state;
  // const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = customerData && customerData.userChecked;
  const position = customerData && customerData.position;
  const customerEmail = customerData && customerData.email;
  const daysOverdue = (customerData && customerData.metafields?.find(meta => meta.key === 'days-overdue')?.value) || 0;
  // const balance = (customerData && customerData.metafields?.find(meta => meta.key === 'balance')?.value) || 0;
  const readNotificationsObj = customerData && customerData.object?.customer?.readNotifications;
  // eslint-disable-next-line
  const readNotifications = getStorage('_readNotifications') ? JSON.parse(getStorage('_readNotifications')) : readNotificationsObj ? JSON.parse(readNotificationsObj.value) : [];
  const [notificationList, setNotificationList] = useState(initialState.notificationList);
  const [notificationCount, setNotificationCount] = useState(initialState.notificationCount);
  const [init, setInit] = useState(true);

  const notificationsWPData = useStaticQuery(graphql`
      query {
        allWpNotification(limit: 999, sort: {date: DESC}) {
          nodes {
            title
            slug
            content
            excerpt
            date
            additionalNotificationFields {
              hyperlink
            }
            targetEmails: additionalNotificationFields {
              target {
                email
              }
            }
            id: databaseId
          }
          pageInfo {
            hasNextPage
          }
        }
      }
  `);

  const notificationsData = notificationsWPData?.allWpNotification?.nodes?.map(node => {
    return {
      id: node.id,
      title: node.title,
      content: node.excerpt,
      url: node.additionalNotificationFields.hyperlink,
      slug: node.slug,
      audience: node.targetEmails?.target?.map(line => line.email) || null,
      startDate: node.date,
      isRead: readNotifications.includes(node.id) ? true : false
    }
  })

  useEffect(() => {
    if (init && userChecked) {
      if ((parseFloat(daysOverdue || 0) > 0) && (parseFloat(daysOverdue || 0) > 0) && (position === 'Owner' || position === 'Manager')) {
        const overDueId = parseInt(moment(new Date()).subtract(daysOverdue, 'days').locale('en').format('DDMMYYYY'));
        notificationsData.unshift({
          id: overDueId,
          title: 'Overdue payment',
          startDate: moment(new Date()).subtract(daysOverdue, 'days'),
          slug: 'overdue',
          isRead: readNotifications.includes(overDueId) ? true : false
        })
      }

      // Filter by email
      const newNotificationData = notificationsData.filter(notification => (!notification.audience) || (notification.audience && notification.audience.includes(customerEmail)))

      setNotificationList(newNotificationData.slice(0, 5));
      const newCount = notificationsData.filter(note => !note.isRead);
      // console.log(newCount);
      setNotificationCount(newCount.length);
      setInit(false);
    }
  }, [notificationsData, init, daysOverdue, readNotifications, position, customerEmail, userChecked])
  // console.log(notificationCount, notificationList);


  const readNotification = async (list) => {
    const ids = readNotifications;
    const newList = [...new Set([...ids, ...list])];
    setStorage('_readNotifications', JSON.stringify(newList));
    const metafield = {
        key: '_readNotifications',
        namespace: 'notifications',
        ownerId: customerData.customerId,
        value: JSON.stringify(newList),
        type: 'multi_line_text_field'
    }
    setMetafield(metafield).then(res => {
      // if (res.status === 200 && res.response.data?.metafieldsSet?.metafields?.length) {
        const newReadNotifications = getStorage('_readNotifications') ? JSON.parse(getStorage('_readNotifications')) : readNotificationsObj ? JSON.parse(readNotificationsObj.value) : [];

        const updatedList = notificationList.map(note => {
          if (newReadNotifications.includes(note.id)) {
            note.isRead = true;
          } else {
            note.isRead = false;
          }
          return note;
        });
        // console.log('updatedList', updatedList);
        setNotificationList(updatedList);
      // }
    });
  }


  return (
    <NotificationContext.Provider
      value={{
        notificationList,
        notificationCount,
        readNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
