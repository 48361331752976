/**
 * Celigo API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * 
 * @return  {Object}                {response, status}
 *
    import { celigoApi } from '../helpers/celigo'

    celigoApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function celigoApi(endpoint, method, body) {
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
        bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }

    const parseJson = async response => {
        const text = await response.text();
        try {
        const json = JSON.parse(text);
        return json;
        } catch (err) {
        return text;
        }
    };

    const encodedEndpoint = Buffer.from(endpoint).toString('base64');

    return await fetch(
        `${process.env.LAMBDA_PATH}celigo?action=${encodedEndpoint}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

export { celigoApi };
      